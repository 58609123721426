import './css.css';
import SectionTitle from '../SectionTitle/element'
import { useCallback, useEffect, useState, useRef } from 'react';

function Element() {
    const containerRef = useRef();
    const [index, setIndex] = useState(0);
    const [isIndexStart, setIndexStart] = useState(true);
    const [isIndexEnd, setIndexEnd] = useState(false);
    const [isSlideCount, setSlideCount] = useState(2);

    const setSlide = useCallback((i) => {
        const container = containerRef.current;
        const count = container.children.length - 2;
        i = i < 0 ? 0 : i > count - 1 ? count - 1 : i;
        setIndexStart(i === 0)
        setIndexEnd(i + 1 > count - isSlideCount)
        setIndex(i);
    }, [isSlideCount]);
    const prev = useCallback(() => setSlide(index - 1), [index, setSlide]);
    const next = useCallback(() => setSlide(index + 1), [index, setSlide]);

    useEffect(() => {
        const handleOrientationChange = (e) => {
            setSlideCount(e.matches ? 2 : 1);
            if (e.isTrusted) setSlide(0);
        };

        const mediaQuery = window.matchMedia('(orientation: landscape)');
        handleOrientationChange(mediaQuery);
        mediaQuery.addEventListener('change', handleOrientationChange);
        return () => mediaQuery.removeEventListener('change', handleOrientationChange);
    }, [setSlide]);

    useEffect(() => {
        const container = containerRef.current;

        let startX, startY;
        const touchstart = (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        }
        const touchend = (e) => {
            if (!startX) return startX = null;
            let deltaX = startX - e.changedTouches[0].clientX;
            let deltaY = startY - e.changedTouches[0].clientY;
            if (Math.abs(deltaX) < Math.abs(deltaY) || Math.abs(deltaX) < 50) return startX = null;
            if (deltaX < 0) prev();
            else if (deltaX > 0) next();
            startX = null;
            startY = null;
        };

        container.addEventListener('touchstart', touchstart);
        container.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
        container.addEventListener('touchend', touchend);
        return () => {
            container.removeEventListener('touchstart', touchstart);
            container.removeEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
            container.removeEventListener('touchend', touchend);
        };
    }, [prev, next]);

    const [dataService, setDataService] = useState([]);
    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=94095624')
            .then(response => response.text())
            .then(text => {
                const json = JSON.parse(text.slice(47, -2));
                const rows = json.table.rows;
                setDataService(rows);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const [startYear, setStartYear] = useState(0);
    const [countYear, setCountYear] = useState(0);
    const [dataHistory, setDataHistory] = useState([]);
    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=510425057')
            .then(response => response.text())
            .then(text => {
                const json = JSON.parse(text.slice(47, -2));
                const rows = json.table.rows;
                setDataHistory(rows);
                setStartYear(rows[0].c[1].v);
                setCountYear(rows[rows.length - 1].c[1].v - rows[0].c[1].v + 1);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const [indexHistory, setIndexHistory] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => indexHistory < countYear - 1 ? setIndexHistory(indexHistory + 1) : setIndexHistory(0), 2500);
        return () => clearInterval(interval);
    }, [indexHistory, countYear]);

    const [dataOrganization, setDataOrganization] = useState([]);
    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1525315964')
            .then(response => response.text())
            .then(text => {
                const json = JSON.parse(text.slice(47, -2));
                const rows = json.table.rows;
                setDataOrganization(rows);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <section id='company'>
            <SectionTitle
                h={'회사소개'}
                p={[['개발기획부터 분양마케팅까지 전략 및 통합 솔루션을 제공합니다.'],
                ['부동산 종합 그룹을 향한 큰 그림을 그려가고 있습니다.'],
                ['창조적이고 혁신적인 아이디어로 부동산 개발의 새로운 패러다임을 선보이겠습니다.'],
                ['창조적이고 혁신적인 아이디어로 부동산 개발의 새로운 패러다임을 선보이겠습니다.']][index]} />
            <div ref={containerRef} className='container'>
                <div className='item' style={{ width: '100%', left: `calc(${(0 - index) * 100}%)` }}>
                    <div className='service'>
                        {dataService.map((item, i) => (
                            <div className='box' key={i}>
                                <img src={item.c[1].v} alt='' />
                                <div className='text'>
                                    <h3>{item.c[2].v}</h3>
                                    <p>{item.c[3].v}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='item' style={{ width: '100%', left: `calc(${(1 - index) * 100}%)` }}>
                    <div className='history'>
                        {Array.from({ length: countYear }).map((_, i) => (
                            <div className='year' style={{ top: `${25 * (i - indexHistory + (i - indexHistory < -1 ? countYear : 0) - (i - indexHistory >= countYear - 1 ? countYear : 0))}rem`, opacity: (i - indexHistory + (i - indexHistory < -1 ? countYear : 0) - (i - indexHistory >= countYear - 1 ? countYear : 0)) > 1 ? 0 : 1 }} key={i}>
                                <i className='fa-solid fa-play' />
                                <h1>{startYear + i}</h1>
                                {dataHistory.map((item, j) => item.c[1].v === startYear + i && (
                                    <div className='box' key={j}>
                                        <h4>{startYear + i}.{item.c[2].f}</h4>
                                        <div className='card'>
                                            <h4>{item.c[3].v}</h4>
                                            <small>{item.c[4].v}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='item padding' style={{ width: isSlideCount === 2 ? '50%' : '100%', left: `calc(${(2 - index) * 100}%)` }}>
                    <div className='organization'>
                        {dataOrganization.map((item, i) => (
                            <div className='box' key={i}>
                                {Array.from({ length: item.c[3].v }).map((_, j) => (
                                    <div className='margin' key={j}>
                                        <div className={j === item.c[3].v - 1 ? 'left bottom' : item.c[5].v ? 'left' : undefined} />
                                        <div className={j === item.c[3].v - 1 ? item.c[4].v ? undefined : 'left' : item.c[5].v ? 'left' : undefined} />
                                    </div>
                                ))}
                                <div className={`card lv${item.c[3].v}`}>
                                    <h4>{item.c[1].v}</h4>
                                    {item.c[2] && <small>{item.c[2].v}</small>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='item padding' style={{ width: isSlideCount === 2 ? '50%' : '100%', left: isSlideCount === 2 ? `calc(${(3 - index) * 100}% - 50%)` : `calc(${(3 - index) * 100}%)` }}>
                    <div className='ceo'>
                        <p>
                            퍼스트씨엔디는 2016년 종합부동산 전문기업으로 출범 후 언제나 새로운 시각과 선구자적 시선으로 급변하는 부동산 시장과 다양한 고객 니즈를 만족시키고자 최선의 노력을 다하고 있습니다.
                        </p>
                        <p>
                            나아가 열린경영, 투명경영, 책임경영의 이념 아래 고객과 사회로부터 신뢰받고 존경 받는 기업.
                        </p>
                        <p>
                            창조적이고 미래 지향적인 사고로 도전과 실패를 두려워 하지 않는 기업.
                        </p>
                        <p>
                            항상 주인의식을 갖고 최대의 만족감과 감동을 줄 수 있는 기업으로 성장해 가겠습니다.
                        </p>
                        <p>감사합니다.</p>
                        <div />
                        <small>퍼스트씨엔디 대표이사</small>
                        <h3>이 원 기</h3>
                    </div>
                </div>
                <div className={isIndexStart ? 'btn prev hide' : index === 0 ? 'btn prev invert' : 'btn prev'} onClick={() => prev()}>
                    <i className='fa-solid fa-arrow-left fa-xl fa-fade' />
                </div>
                <div className={isIndexEnd ? 'btn next hide' : index === 0 ? 'btn next invert' : 'btn next'} onClick={() => next()}>
                    <i className='fa-solid fa-arrow-right fa-xl fa-fade' />
                </div>
            </div>

        </section >
    );
}

export default Element;
