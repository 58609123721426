import './css.css';
import SectionTitle from '../SectionTitle/element'
import { useEffect, useState } from 'react';

function Element() {
    const [dataContact, setDataContact] = useState([]);
    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1264279400')
            .then(response => response.text())
            .then(text => {
                const json = JSON.parse(text.slice(47, -2));
                const rows = json.table.rows;
                setDataContact(rows[0].c);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <section id='contact'>
            <SectionTitle h='오시는길' p={['퍼스트씨엔디로 오시는 길을 안내드립니다.']} />
            <a className='map' title='지도 연결' href={dataContact.length > 0 ? dataContact[1].v : 'https://firstcnd.com/'} target='_blank' rel='noreferrer'>
                <i className='fa-solid fa-location-dot fa-2xl fa-fade' />
                <img src={dataContact.length > 0 ? dataContact[0].v : ''} alt='' />
            </a>
            <div className='container'>
                <a className='item' title='지도 연결' href={dataContact.length > 0 ? dataContact[1].v : 'https://firstcnd.com/'} target='_blank' rel='noreferrer'                >
                    <i className='fa-solid fa-location-dot' />
                    <small>
                        {dataContact.length > 0 ? dataContact[2].v : ''}
                        <br />
                        {dataContact.length > 0 ? dataContact[3].v : ''}
                    </small>
                </a>
                <a className='item' title='메일 연결' href={`mailto:${dataContact.length > 0 ? dataContact[4].v : ''}`}>
                    <i className='fa-solid fa-envelope' />
                    <small>
                        {dataContact.length > 0 ? dataContact[4].v : ''}
                    </small>
                </a>
                <a className='item' title='전화 연결' href={`tel:+82${dataContact.length > 0 ? dataContact[5].v : ''}`}>
                    <i className='fa-solid fa-phone' />
                    <small>
                        {dataContact.length > 0 ? dataContact[5].f : ''}
                    </small>
                </a>
                <a className='item' title='팩스 연결' href={`tel:+82${dataContact.length > 0 ? dataContact[6].v : ''}`}>
                    <i className='fa-solid fa-fax' />
                    <small>
                        {dataContact.length > 0 ? dataContact[6].f : ''}
                    </small>
                </a>
            </div>
        </section >
    );
}

export default Element;
