import './css.css';
import { useCallback, useEffect, useState, useRef } from 'react';

function Element() {
    const containerRef = useRef();
    const [index, setIndex] = useState(0);
    const [isIndexStart, setIndexStart] = useState(true);
    const [isIndexEnd, setIndexEnd] = useState(false);
    const [isSlideCount, setSlideCount] = useState(3);
    const [isMouseOver, setMouseOver] = useState(false);
    const [indexMouseOver, setIndexMouseOver] = useState(0);

    const setSlide = useCallback((i) => {
        const container = containerRef.current;
        const count = container.children.length - 2;
        i = i < 0 ? 0 : i > count - 1 ? count - 1 : i;
        setIndexStart(i === 0)
        setIndexEnd(i + 1 > count - isSlideCount)
        setIndex(i);
    }, [isSlideCount]);
    const prev = useCallback(() => setSlide(index - 1), [index, setSlide]);
    const next = useCallback(() => setSlide(index + 1), [index, setSlide]);

    useEffect(() => {
        const handleOrientationChange = (e) => {
            setSlideCount(e.matches ? 3 : 1);
            if (e.isTrusted) setSlide(0);
        };

        const mediaQuery = window.matchMedia('(orientation: landscape)');
        handleOrientationChange(mediaQuery);
        mediaQuery.addEventListener('change', handleOrientationChange);
        return () => mediaQuery.removeEventListener('change', handleOrientationChange);
    }, [setSlide]);

    useEffect(() => {
        const container = containerRef.current;

        let startX, startY;
        const touchstart = (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        }
        const touchend = (e) => {
            if (!startX) return startX = null;
            let deltaX = startX - e.changedTouches[0].clientX;
            let deltaY = startY - e.changedTouches[0].clientY;
            if (Math.abs(deltaX) < Math.abs(deltaY) || Math.abs(deltaX) < 50) return startX = null;
            if (deltaX < 0) prev();
            else if (deltaX > 0) next();
            startX = null;
            startY = null;
        };

        container.addEventListener('touchstart', touchstart);
        container.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
        container.addEventListener('touchend', touchend);
        return () => {
            container.removeEventListener('touchstart', touchstart);
            container.removeEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
            container.removeEventListener('touchend', touchend);
        };
    }, [prev, next]);

    const [dataProject, setDataProject] = useState([]);
    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1925241063')
            .then(response => response.text())
            .then(text => {
                const json = JSON.parse(text.slice(47, -2));
                const rows = json.table.rows;
                setDataProject(rows);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <section id='project'>
            <div>
                {dataProject.slice().reverse().map((item, i) => (
                    <img
                        className='background'
                        src={item.c[1].v}
                        style={{ opacity: indexMouseOver === i ? isSlideCount === 3 ? 1 : 0 : 0 }}
                        alt=''
                        key={i}
                    />
                ))}
            </div>
            <div ref={containerRef} className='container'>
                {dataProject.slice().reverse().map((item, i) => (
                    <div
                        className='item'
                        style={{ width: `${100 / isSlideCount}%`, left: `${(i - index) * 100 / isSlideCount}%` }}
                        onMouseEnter={(e) => {
                            setMouseOver(true);
                            setIndexMouseOver(Array.from(e.target.parentNode.children).indexOf(e.target));
                        }}
                        onMouseLeave={() => setMouseOver(false)}
                        key={i}
                    >
                        <img style={{ opacity: isMouseOver ? isSlideCount === 3 ? 0 : 1 : 1 }} src={item.c[1].v} alt='' />
                        <div className='text'>
                            <h3 className='title'>
                                {item.c[2].v}
                            </h3>
                            <div className='card first hover'><div>소재지</div>{item.c[4] && item.c[4].v}</div>
                            <div className='card hover'><div>연면적</div>{item.c[5] && item.c[5].v}</div>
                            <div className='card hover'><div>규모</div>{item.c[6] && item.c[6].v}</div>
                            <div className='card hover'><div>사업유형</div>{item.c[7] && item.c[7].v}</div>
                            <div className='card hover'><div>사업주체</div>{item.c[8] && item.c[8].v}</div>
                        </div>
                    </div>
                ))}
                <div className={isIndexStart ? 'btn prev hide' : 'btn prev invert'} onClick={() => prev()}>
                    <i className='fa-solid fa-arrow-left fa-xl fa-fade' />
                </div>
                <div className={isIndexEnd ? 'btn next hide' : 'btn next invert'} onClick={() => next()}>
                    <i className='fa-solid fa-arrow-right fa-xl fa-fade' />
                </div>
            </div>
        </section >
    );
}

export default Element;
