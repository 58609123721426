import './css.css';

function Element() {
    return (
        <footer id='footer'>
            <small className='h'>
                법인명 <b>퍼스트씨엔디(주)</b> | 사업자번호 <b>456-87-00223</b> | 대표자 <b>이원기</b>
                <br />
                <br />
                Copyright for <b>FIRST C&D</b> all rights reserved.
            </small>
            <small className='v'>
                법인명 <b>퍼스트씨엔디(주)</b>
                <br />
                사업자번호 <b>456-87-00223</b>
                <br />
                대표자 <b>이원기</b>
                <br />
                <br />
                Copyright for <b>FIRST C&D</b> all rights reserved.
            </small>
        </footer >
    );
}

export default Element;
