import Header from './elements/Header/element';
import Footer from './elements/Footer/element';
import Home from './elements/Home/element';
import Company from './elements/Company/element';
import Project from './elements/Project/element';
import Article from './elements/Article/element';
import Contact from './elements/Contact/element';

function App() {
    return (
        <>
            <div className='noise' />
            <Header />
            <main id='main'>
                <Home />
                <Company />
                <Project />
                <Article />
                <Contact />
                <Footer />
            </main>
        </>
    );
}

export default App;
