import './css.css';
import SectionTitle from '../SectionTitle/element'
import { useCallback, useEffect, useState, useRef } from 'react';

function Element() {
    const containerRef = useRef();
    const [index, setIndex] = useState(0);
    const [isIndexStart, setIndexStart] = useState(true);
    const [isIndexEnd, setIndexEnd] = useState(false);
    const [isSlideCount, setSlideCount] = useState(3);

    const setSlide = useCallback((i) => {
        const container = containerRef.current;
        const count = container.children.length - 3;
        i = i < 0 ? 0 : i > count - 1 ? count - 1 : i;
        setIndexStart(i === 0)
        setIndexEnd(i + 1 === count)
        setIndex(i);
    }, []);
    const prev = useCallback(() => setSlide(index - 1), [index, setSlide]);
    const next = useCallback(() => setSlide(index + 1), [index, setSlide]);

    useEffect(() => {
        const handleOrientationChange = (e) => setSlideCount(e.matches ? 1 : 3);

        const mediaQuery = window.matchMedia('(orientation: portrait)');
        handleOrientationChange(mediaQuery);
        mediaQuery.addEventListener('change', handleOrientationChange);
        return () => mediaQuery.removeEventListener('change', handleOrientationChange);
    }, []);

    useEffect(() => {
        const container = containerRef.current;

        let startX, startY;
        const touchstart = (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        }
        const touchend = (e) => {
            if (!startX) return startX = null;
            let deltaX = startX - e.changedTouches[0].clientX;
            let deltaY = startY - e.changedTouches[0].clientY;
            if (Math.abs(deltaX) < Math.abs(deltaY) || Math.abs(deltaX) < 50) return startX = null;
            if (deltaX < 0) prev();
            else if (deltaX > 0) next();
            startX = null;
            startY = null;
        };

        container.addEventListener('touchstart', touchstart);
        container.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
        container.addEventListener('touchend', touchend);
        return () => {
            container.removeEventListener('touchstart', touchstart);
            container.removeEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
            container.removeEventListener('touchend', touchend);
        };
    }, [prev, next]);

    const [dataArticle, setDataArticle] = useState([]);
    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=504485254')
            .then(response => response.text())
            .then(text => {
                const json = JSON.parse(text.slice(47, -2));
                const rows = json.table.rows;
                setDataArticle(rows);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <section id='article'>
            <SectionTitle h='회사소식' p={['퍼스트씨엔디의 소식을 전해드립니다.']} />
            <div ref={containerRef} className='container'>
                <img
                    className='background'
                    src={
                        dataArticle.slice().reverse()[index] && dataArticle.slice().reverse()[index].c[1] ?
                            dataArticle.slice().reverse()[index].c[1].v :
                            'https://drive.google.com/thumbnail?sz=w1920&id=1tpb5gTkHPBVs_R3Ssc5thGXMUDZu5MuS'
                    }
                    alt=''
                />
                {dataArticle.slice().reverse().map((item, i) => (
                    <div
                        className={i === index ? 'item active' : 'item'}
                        style={{
                            width: isSlideCount === 3 ? `calc(${i === index ? 50 : 25}% - 2rem)` : 'calc(100% - 2rem)',
                            left: isSlideCount === 3 ? `calc(${i === index ? 25 : i < index ? (i - index) * 25 + 25 : (i - index) * 25 + 50}% + 1rem)` : `calc(${i === index ? 0 : i < index ? (i - index) * 100 : (i - index) * 100}% + 1rem)`
                        }}
                        key={i}
                    >
                        <h3 className='title'>{item.c[2].v}</h3>
                        <small className='time'>
                            <i className='fa-regular fa-clock' />
                            {item.c[3].f}
                        </small>
                        <div className='text'>
                            <p>{item.c[4].v}</p>
                            {item.c[5].v &&
                                <a href={item.c[5].v} target='_blank' rel='noreferrer'>
                                    기사 바로가기 <i className='fa-solid fa-arrow-up-right-from-square' />
                                </a>
                            }
                        </div>
                    </div>
                ))}
                <div className={isIndexStart ? 'btn prev hide' : 'btn prev'} onClick={() => prev()}>
                    <i className='fa-solid fa-arrow-left fa-xl fa-fade' />
                </div>
                <div className={isIndexEnd ? 'btn next hide' : 'btn next'} onClick={() => next()}>
                    <i className='fa-solid fa-arrow-right fa-xl fa-fade' />
                </div>
            </div>
        </section >
    );
}

export default Element;
