import './css.css';

function Element({ h, p = '' }) {
    return (
        <div className='sectionTitle'>
            <div className='title'>{h}</div>
            {p.map((item, index) => (
                <p key={index}>
                    {item}
                </p>
            ))}
        </div>
    );
}

export default Element;
