import './css.css';
import logo from '../logo.svg';
import { useCallback, useEffect, useState } from 'react';

function Element() {
    const [isVisibleBars, setVisibleBars] = useState(false);
    const [isInvert, setInvert] = useState(true);
    const [index, setIndex] = useState(0);

    const setScroll = useCallback((i) => {
        const main = document.getElementById('main');
        const section = main.children[i];
        i === 0 || i === 2 ? setInvert(true) : setInvert(false);
        main.style.transform = `translateY(${window.innerHeight - section.clientHeight - section.offsetTop}px)`;
        setIndex(i);
    }, []);

    useEffect(() => {
        const main = document.getElementById('main');
        const count = main.children.length - 1;

        const wheel = (e) => {
            if (e.deltaY < 0) setScroll(index > 0 ? index - 1 : 0);
            else if (e.deltaY > 0) setScroll(index < count ? index + 1 : count);
        };
        let startX, startY;
        const touchstart = (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        }
        const touchend = (e) => {
            if (!startY) return startY = null;
            let deltaX = startX - e.changedTouches[0].clientX;
            let deltaY = startY - e.changedTouches[0].clientY;
            if (Math.abs(deltaX) > Math.abs(deltaY) || Math.abs(deltaY) < 50) return startY = null;
            if (deltaY < 0) setScroll(index > 0 ? index - 1 : 0);
            else if (deltaY > 0) setScroll(index < count ? index + 1 : count);
            startX = null;
            startY = null;
        };

        window.addEventListener('wheel', wheel);
        window.addEventListener('touchstart', touchstart);
        window.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
        window.addEventListener('touchend', touchend);
        return () => {
            window.removeEventListener('wheel', wheel);
            window.removeEventListener('touchstart', touchstart);
            window.removeEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
            window.removeEventListener('touchend', touchend);
        };
    }, [index, setScroll]);

    return (
        <header id='header' className={isInvert ? 'invert' : undefined}>
            <nav>
                <div id='logo' onClick={() => { setVisibleBars(false); setScroll(0); }}>
                    <img src={logo} alt='logo' />
                    FIRST C&D
                </div>
                <ul>
                    <li className={isVisibleBars ? 'bars show' : 'bars hide'} onClick={() => setVisibleBars(!isVisibleBars)}>
                        <i className={isVisibleBars ? 'fa-solid fa-xmark fa-xl' : 'fa-solid fa-bars fa-xl'} />
                    </li>
                    <li onClick={() => { setVisibleBars(false); setScroll(1); }}>
                        <h4>회사소개</h4>
                    </li>
                    <li onClick={() => { setVisibleBars(false); setScroll(2); }}>
                        <h4>프로젝트</h4>
                    </li>
                    <li onClick={() => { setVisibleBars(false); setScroll(3); }}>
                        <h4>회사소식</h4>
                    </li>
                    <li onClick={() => { setVisibleBars(false); setScroll(4); }}>
                        <h4>오시는길</h4>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Element;
