import './css.css';
import { useState, useRef } from 'react';

function Element() {
    const containerRef = useRef();
    const [index, setIndex] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    return (
        <section id='home'>
            <div className='text'>
                <div className='slogan'>First is Best!</div>
                <div className='title'>최고의 결과를 위해 과감히 퍼스트가 되고,</div>
                <div className='title'>베스트를 다해 최고임을 증명합니다.</div>
                <div className='slogan'>Best is First!</div>
            </div>
            <div ref={containerRef} className='container'>
                <div className='item' style={{ opacity: index === 0 ? 1 : 0 }}>
                    <img
                        style={{ objectPosition: '80% top', }}
                        src='https://drive.google.com/thumbnail?sz=w1920&id=1tpb5gTkHPBVs_R3Ssc5thGXMUDZu5MuS'
                        alt=''
                    />
                </div>
                <div className='item' style={{ opacity: index === 1 ? 1 : 0 }}>
                    <video
                        playsInline muted autoPlay loop
                        onPlay={(e) => e.target.playbackRate = .5}
                        onTimeUpdate={(e) => {
                            if (e.target.currentTime < currentTime) {
                                const container = containerRef.current;
                                const count = container.children.length;
                                setIndex(index + 1 < count ? index + 1 : 0);
                            }
                            setCurrentTime(e.target.currentTime);
                        }}
                    >
                        <source src={require(`./main-0.mp4`)} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
}

export default Element;
